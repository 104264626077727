/**************************************************
 * Nombre:       Formulario_Datos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Apartment, Flag, LocationCity, MapsHomeWork, MarkAsUnread} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {obtenerDepartamentos} from "../../Modulo_envios/Funciones/obtenerDepartamentos";
import {obtenerPaises} from "../../Modulo_envios/Funciones/obtenerPaises";

const Formulario_Datos_Envio = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                    <Typography sx={{color: '#00000080', fontSize: 14}}>
                        Shipping data
                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelect Icono={Flag} nombre={'Country'} dato={'pais'}
                                   opciones={obtenerPaises()} {...props}
                                   requerido={'The country to send is required'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Apartment} nombre={'State'} dato={'estado'}
                                    {...props}
                                   requerido={'The state to send is required'}/>

                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={MapsHomeWork} nombre={'City'} dato={'ciudad'}
                                   {...props}
                                  requerido={'The city to send is required'}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={MarkAsUnread} nombre={'Zip code'} dato={'codigoPostal'} {...props}
                                  requerido={'The zip code to send is required'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={MapsHomeWork} nombre={'Address'} dato={'direccion'} {...props}
                                  requerido={'The adress to send is required'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Flag} nombre={'Additional information'}
                                  dato={'indicaciones'} {...props} lineas={3}/>
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Datos_Envio